<template>
	<div class="content">
		<div class="set">
			<h4 class="global_head_title">销售阶段设置</h4>
			<div class="set_ flex">
				<div class="set_label">
					<label for="" v-for="(item, index) in setting.section" :key="index">阶段{{ Number(index) + 1 }}</label>
				</div>
				<draggable v-model="setting.section" animation="1000" handle=".move" draggable=".set_con">
					<div class="set_con" v-for="(item, index) in setting.section" :key="index">
						<input type="text" placeholder="" v-model="item.value">
						<img src="@/assets/imgs/del.png" alt="删除" @click="del(index, setting.section)">
						<img class="move" src="@/assets/imgs/move.png" alt="移动" @start="onStart" @end="onEnd">
					</div>
				</draggable>
			</div>
			<div><a-button class="add" type="link" @click="add(setting.section)"><img src="@/assets/imgs/add.png"
						alt="添加">添加</a-button></div>
		</div>
		<div class="set">
			<h4 class="global_head_title">放弃跟进原因设置</h4>
			<div class="set_">
				<div class="flex">
					<label for="">丢单</label>
					<!-- <input class="reasonInput" type="text" placeholder="" v-model="value"> -->
					<div class="reasonBg">
						<draggable v-model="setting.give_up" animation="1000" handle=".move" draggable=".set_con">
							<div class="set_con" v-for="(item, index) in setting.give_up" :key="index">
								<input type="text" placeholder="" v-model="item.value">
								<img src="@/assets/imgs/del.png" alt="删除" @click="del(index, setting.give_up)">
								<img class="move" src="@/assets/imgs/move.png" alt="移动" @start="onStart" @end="onEnd">
							</div>
						</draggable>
						<div><a-button class="add" type="link" @click="add(setting.give_up)"><img
									src="@/assets/imgs/add.png" alt="添加">添加</a-button></div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="set">
			<h4 class="global_head_title">无效客户设置</h4>
			<div class="set_" >
				<div class="flex">
					<label for="">无效</label>
					<div class="reasonBg">
						<draggable v-model="setting.invalid" animation="1000" handle=".move" draggable=".set_con">	
							<div class="set_con" v-for="(item,index) in setting.invalid" :key="index">
								<input type="text" placeholder="" v-model="item.value">
								<img src="@/assets/imgs/del.png" alt="删除" @click="del(index,setting.invalid)">
								<img class="move" src="@/assets/imgs/move.png" alt="移动"  @start="onStart" @end="onEnd" >
							</div>
						</draggable>
						<div><a-button class="add" type="link" @click="add(setting.invalid)"><img src="@/assets/imgs/add.png" alt="添加">添加</a-button></div>
					</div>
				</div>
				
			</div>
		</div> -->
		<div class="set">
			<div class="set_">
				<!-- <div class="">
					<label for="">审批配置</label>
					是否审批<a-switch v-model="setting.approve"></a-switch>
				</div>
				<div class="">
					<label for="">日报生成时间</label>
					<a-time-picker v-model="business" format="HH:mm"/>
				</div> -->
				<div class="">
					<label for="">客户生日提醒</label>
					<a-select size="large" v-model="setting['birthday']" placeholder="">
						<a-select-option v-for="(item, index) in dateArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="">
					<label for="">订单到期提醒</label>
					<a-select size="large" v-model="setting['maturity']" placeholder="">
						<a-select-option v-for="(item, index) in dateArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="">
					<label for="">最低跟进频率</label>
					<a-select size="large" v-model="setting['frequency']" placeholder="">
						<a-select-option v-for="(item, index) in frequencyArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="">
					<label for="">客户跟进时长</label>
					<a-input-number size="large" v-model="setting['followday']" :min="0" prefix="天" :formatter="value => `${value}天`"
						:parser="value => value.replace('天', '')"></a-input-number>
					<!-- <a-select size="large" v-model="setting['frequency']" placeholder="">
						<a-select-option v-for="(item,index) in frequencyArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select> -->
				</div>
			</div>
		</div>
		<div class="set">
			<h4 class="global_head_title">订单设置功能</h4>
			<div class="set_" >
				<div class="">
					<label for="">进件订单金额</label>
					<a-input-number size="large" v-model="setting['jj_order_money']" :min="0"></a-input-number>
				</div>
				<div class="">
					<label for="">培训订单金额</label>
					<a-input-number size="large" v-model="setting['px_order_money']" :min="0"></a-input-number>
				</div>
			</div>
		</div>
		<div class="set">
			<h4 class="global_head_title">工资设置功能</h4>
			<div class="set_" >
				<div class="">
					<label for="">工资周期</label>
					<a-select size="large" v-model="setting['pay_period']" placeholder="">
						<a-select-option v-for="(item, index) in wageArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="">
					<label for="">计算方式</label>
					<a-select size="large" v-model="setting['count_mode']" placeholder="">
						<a-select-option v-for="(item, index) in countArr" :key="index" :value="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select>
				</div>
				<div class="">
					<label for="">基础工资</label>
					<a-input-number size="large" v-model="setting['base_salary']" :min="0"></a-input-number>
				</div>
			</div>
		</div>
		<div><a-button class="save" type="primary" @click="save">保存</a-button></div>
	</div>
</template>
<script>
// import $ from "jquery";
import { requestXml } from '../../../assets/js/request';
import draggable from 'vuedraggable';
var moment = require('moment');
export default {
	components: {
		draggable
	},
	data() {
		return {
			drag: false,
			dateArr: [
				{ "value": "0", "label": "不提醒" },
				{ "value": "1", "label": "提前一天" },
				{ "value": "2", "label": "提前三天" },
				{ "value": "3", "label": "提前一周" },
				{ "value": "4", "label": "提前两周" },
				{ "value": "5", "label": "提前一个月" },
			],
			frequencyArr: [
				{ "value": "1", "label": "每天一次" },
				{ "value": "3", "label": "三天一次" },
				{ "value": "7", "label": "一周一次" },
				{ "value": "14", "label": "两周一次" },
				{ "value": "30", "label": "一月一次" },
				{ "value": "90", "label": "三月一次" },
				{ "value": "180", "label": "半年一次" },
				{ "value": "365", "label": "一年一次" },
			],
			wageArr: [
				{ "value": "1", "label": "按日计算" },
				{ "value": "2", "label": "按月计算" },
			],
			countArr: [
				{ "value": "1", "label": "按单计算" },
				{ "value": "2", "label": "按收款金额计算" },
			],
			business: '',
			setting: {
				followday: 0,
				jj_order_money:0,
				px_order_money:0,
				birthday: "1",
				maturity: "1",
				frequency: "3",
				pay_period:'',
				count_mode:'',
				base_salary:'',
				business: "",
				give_up: [
					{ "name": "1", "value": "客户不想购买了" },
					{ "name": "2", "value": "被竞争对手抢单" },
					{ "name": "3", "value": "未满足客户需求" },
					{ "name": "4", "value": "其他" },
				],
				invalid: [
					{ "name": "1", "value": "客户不想购买了" },
					{ "name": "2", "value": "被竞争对手抢单" },
					{ "name": "3", "value": "未满足客户需求" },
					{ "name": "4", "value": "其他" },
				],
				approve: true,
				section: [
					{ "name": "1", "value": "已加微信" },
					{ "name": "2", "value": "新线索" },
					{ "name": "3", "value": "多次成交" },
					{ "name": "4", "value": "电话沟通" },
					{ "name": "5", "value": "产品演示" },
					{ "name": "6", "value": "订单报价" },
					{ "name": "7", "value": "已成交" },
				],
			}
		};
	},
	mounted: function () {
		this.getSet();

	},
	methods: {
		add(arr) {
			var obj = { "name": arr.length + 1, "value": "" };
			arr.push(obj)
		},
		del(index, arr) {
			arr.splice(index, 1)
		},
		onStart() {
			this.drag = true;
		},
		onEnd() {
			this.drag = false;
		},
		save() {
			for (let i in this.setting) {
				if (i == "give_up" || i == "invalid" || i == "section") {
					if (this.setting[i].length == 0) {
						this.setting[i] = [];
					} else {
						for (let j = 0; j < this.setting[i].length; j++) {
							if (this.setting[i][j].value == "") {
								console.log(this.setting[i], 222)
								this.setting[i].splice(j);

								if (this.setting[i] == []) {
									this.setting[i] = "";
								}
							}
						}
					}
				}
			}

			if (this.business) {
				this.setting.business = this.business.get('hour') + ":" + this.business.get('minute');
			}

			requestXml("/scrm/setting/setSetting", "POST", (res) => {
				if (res == "success") {
					this.getSet();
				}

			}, this.setting)
		},
		getSet() {
			requestXml("/scrm/setting/getSetting", "GET", (res) => {
				if (res.business) {
					this.business = moment(res.business, "h:mm");
				}
				if (res.approve) {
					res.approve = Boolean(res.approve);
				}
				this.setting = res;
			})
		}

		// getList: function() {
		//     $.ajax({
		//         type: "POST",
		//         url: this.url + "scrm/Staff/getList",
		//         success: function(res) {
		//             // console.log(res.setting);

		//         }
		//     });
		// }
	},
};
</script>
<style scoped>
.global_head_title {
	padding-bottom: 10px;
}

.content {
	text-align: left;
}

.set {
	margin-bottom: 40px;
}

.set .set_ {
	align-items: center;
}

.set .set_ .ant-switch {
	margin-left: 10px;
}

.set .set_ .set_label {
	width: 90px;
}

.set .set_ .set_label label {
	height: 50px;
	line-height: 50px;
}

.set label {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	width: 100px;
	margin-bottom: 14px;
	text-align: center !important;
	color: #999;
	text-align: left;
}

.set input {
	width: 320px;
	height: 38px;
	line-height: 38px;
	background: #FFF;
	border: 1px solid #D1D1D1;
	border-radius: 6px;
	padding-left: 15px;
}

.set .reasonInput {
	width: 400px;
	height: 38px;
	line-height: 38px;
	background: #FFF;
	border: 1px solid #D1D1D1;
	border-radius: 6px;
	padding-left: 15px;
	margin-top: 5px;
}

.set .reasonBg {
	background: #F3F3F3;
	width: 400px;
	padding: 20px 0 0;
	margin-bottom: 20px;
}

.set .reasonBg input {
	width: 300px;
	margin: 0 auto;
}

.set .set_con {
	text-align: center;
	width: 400px;
	height: 50px;
	line-height: 50px;
	background: #F3F3F3;
	border-radius: 2px;
	margin-bottom: 14px;
}

.set img {
	width: 22px;
	height: 22px;
	margin-left: 10px;
	cursor: pointer;
}

/* input ,selsct */
.set_ .ant-input,
.set_ .ant-select,
.set_ .ant-input-number {
	width: 400px;
}

.set .add,
.set .save {
	margin-left: 80px;
}

.set .add {
	padding: 0;
	margin: 10px 0 20px 80px;
}

.set .reasonBg .add {
	margin: 0 0 10px 10px;
}
</style>
